import interact from 'interactjs';
import anime from 'animejs';
import { onBeforeUnmount, Ref } from 'vue';

export interface InteractiveResult {
    enable: (clickOnly?: boolean) => void;
    disable: () => void;
    moveTo: (nx: number, ny: number, duration: number) => void;
    shake: (xMax: number) => void;
    slideDownAndFade: (ny: number) => void;
}

export interface InteractiveOptions {
    el: Ref<HTMLElement | null>;
    onDragMove: (x: number, y: number) => void;
    onDragStart: (x: number, y: number) => void;
    onDragEnd: (x: number, y: number) => void;
    onClick: () => void;
    onMoveAnimeStart: () => void;
    onMoveAnimeComplete: () => void;
}

export function useInteractive(options: InteractiveOptions): InteractiveResult {
    // eslint-disable-next-line
    let dragInteractable: any = null;
    let x = 0;
    let y = 0;
    // eslint-disable-next-line
    let isDragging = false;
    let clickEnabled = false;
    let dragEnabled = false;

    const enablePointerEvents = () => {
        if (options.el.value) {
            options.el.value.style.pointerEvents = 'all';
        }
    };

    const disablePointerEvents = () => {
        if (options.el.value && !dragEnabled && !clickEnabled) {
            options.el.value.style.pointerEvents = 'none';
        }
    };

    const enableDrag = () => {
        dragEnabled = true;
        if (dragInteractable) {
            return;
        }
        if (!options.el.value) {
            return;
        }
        dragInteractable = interact(options.el.value).draggable({
            listeners: {
                // eslint-disable-next-line
                move: (event: any) => {
                    const { target } = event;
                    // keep the dragged position
                    const ox = x + event.dx;
                    const oy = y + event.dy;

                    // translate the element
                    target.style.transform = `translateX(${ox}px) translateY(${oy}px)`;

                    // update the position
                    x = ox;
                    y = oy;
                    options.onDragMove(x, y);
                },
                end: () => {
                    isDragging = false;
                    options.onDragEnd(x, y);
                },
                start: () => {
                    // eslint-disable-next-line
                    isDragging = true;
                    options.onDragStart(x, y);
                },
            },
        });
        dragInteractable.on('tap', () => {
            options.onClick();
        });
    };

    const disableDrag = () => {
        if (dragInteractable) {
            dragInteractable.unset();
            dragInteractable = null;
        }
    };

    const enableClick = () => {
        clickEnabled = true;
        disableDrag();
        enablePointerEvents();
        if (options.el.value) {
            (options.el.value as HTMLElement).style.cursor = 'pointer';
            (options.el.value as HTMLElement).onclick = () => {
                options.onClick();
            };
        }
    };

    const disableClick = () => {
        if (options.el.value) {
            (options.el.value as HTMLElement).style.cursor = 'default';
            (options.el.value as HTMLElement).onclick = null;
        }
    };

    const enable = (clickOnly = false) => {
        enablePointerEvents();
        if (clickOnly) {
            enableClick();
            disableDrag();
        } else {
            enableDrag();
            disableClick();
        }
    };

    const disable = () => {
        dragEnabled = false;
        clickEnabled = false;
        disablePointerEvents();
        disableDrag();
        disableClick();
    };

    const moveTo = (nx: number, ny: number, duration: number) => {
        if (!options.el.value) {
            return;
        }
        if (nx == x && ny == y) {
            return;
        }
        if (duration > 0) {
            options.onMoveAnimeStart();
            anime({
                targets: options.el.value,
                translateX: nx,
                translateY: ny,
                easing: 'easeOutQuart',
                duration,
            }).finished.finally(() => {
                x = nx;
                y = ny;
                options.onMoveAnimeComplete();
            });
        } else if (options.el.value) {
            options.el.value.style.transform = `translateX(${nx}px) translateY(${ny}px)`;
            x = nx;
            y = ny;
        }
    };

    const slideDownAndFade = (ny: number) => {
        anime({
            targets: options.el.value,
            easing: 'easeOutExpo',
            opacity: 0,
            translateY: ny,
            duration: 500,
        }).finished.finally(() => {
            y = ny;
            options.onMoveAnimeComplete();
        });
    };

    const shake = (xMax: number) => {
        if (!options.el.value) {
            return;
        }
        anime({
            targets: options.el.value,
            easing: 'easeInOutSine',
            duration: 400,
            translateX: [
                {
                    value: x - xMax * -1,
                },
                {
                    value: x - xMax,
                },
                {
                    value: x - xMax / -2,
                },
                {
                    value: x - xMax / 2,
                },
                {
                    value: x,
                },
            ],
        });
    };

    onBeforeUnmount(() => {
        disableDrag();
    });

    return {
        enable,
        disable,
        moveTo,
        shake,
        slideDownAndFade,
    };
}
