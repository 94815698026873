// https://stackoverflow.com/questions/33716998/why-does-seed-9301-49297-233280-233280-0-generate-a-random-number
// https://en.wikipedia.org/wiki/Linear_congruential_generator
// https://stackoverflow.com/questions/424292/seedable-javascript-random-number-generator

export class ShuffleUtil {
    shuffle<T>(array: T[], seed: number) {
        let m = array.length;

        while (m) {
            const i = Math.floor(this.random(seed) * m--);
            const t = array[m];
            array[m] = array[i];
            array[i] = t;
            seed++;
        }

        return array;
    }

    // this random option looks to generate better array shuffle then the predictable-random option
    private random(seed: number) {
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    }
}

export const shuffleUtil = new ShuffleUtil();
