import { CardDisplayBase } from '@/core/card-display.base';

export class CardDisplayFactory {
    private inst: CardDisplayBase | null = null;

    setInstance(display: CardDisplayBase) {
        this.inst = display;
    }

    clearInstance() {
        if (this.inst) {
            this.inst.destroy();
            this.inst = null;
        }
    }

    get get() {
        if (!this.inst) {
            throw new Error('no display instance, use setInstance');
        }
        // eslint-disable-next-line
        return this.inst!;
    }
}

export const cardDisplayFactory = new CardDisplayFactory();
