import { Controller } from '@/core/controller';
import { gameQuery } from '@/state/game/game.query';
import { GameStatus } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { shuffleUtil } from '@/core/shuffle';
import { cardsService } from '@/state/cards/cards.service';
import { coreBus } from '@/core/core-bus';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { gameService } from '@/state/game/game.service';
import { cardSound } from '@/core/sound';

export abstract class CardDealerBaseController extends Controller {
    private interval: number;

    private take: number;

    protected abstract dealCard(index: number): void;

    constructor(interval: number, take: number) {
        super();
        this.interval = interval;
        this.take = take;
        this.subscribeTo(gameQuery.gameStatus$, (status) => {
            if (status == GameStatus.readyToDeal) {
                cardSound.playStart();
                this.shuffle();
                gameService.setGameStatus(GameStatus.deal);
            } else if (status == GameStatus.deal) {
                this.deal();
            }
        });
    }

    private shuffle() {
        const gameId = gameQuery.getValue().gameId;
        if (!gameId) {
            throw new Error('empty game id');
        }

        const cards = cardsQuery.getAll();
        const shuffled = shuffleUtil.shuffle(cards, gameId);

        const updates = shuffled.map((c, i) => ({
            ...c,
            order: i + 1,
        }));

        cardsService.upsertMany(updates);

        shuffled.forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 0,
            });
        });
    }

    private deal() {
        const sub = interval(this.interval)
            .pipe(take(this.take))
            .subscribe({
                next: (i) => {
                    this.dealCard(i + 1);
                },
                complete: () => {
                    gameService.setGameStatus(GameStatus.dealCompleted);
                    cardsService.updateTableauCount();
                },
            });
        this.subscription.add(sub);
    }
}
