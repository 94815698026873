import { Size, Display as DisplayContract, Card, Position } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { Subscription } from 'rxjs';
import { gameQuery } from '@/state/game/game.query';

export abstract class CardDisplayBase implements DisplayContract {
    cardSize: Size = { width: 0, height: 0 };
    gameSize: Size = { width: 0, height: 0 };
    isPortrait = false;
    tableauCardSpace = 0;
    protected spaceHorizontal = 0;

    private subscription: Subscription = new Subscription();

    private readonly maxColumns: number;

    protected abstract calcDisplay(): void;

    abstract calcCardPosition(card: Card): Position;

    protected constructor(maxColumns: number) {
        this.maxColumns = maxColumns;
    }

    init() {
        this.subscription.add(
            gameQuery.gameSize$.subscribe((size) => {
                this.gameSize = size;
                this.doCalcDisplay();
            })
        );
    }

    private doCalcDisplay() {
        this.isPortrait = this.gameSize.height > this.gameSize.width;

        const big = this.maxColumns > 8 ? 0.015 : 0.025;
        const small = this.maxColumns > 8 ? 0.005 : 0.012;
        const spaceMultiplier = this.gameSize.width > 600 ? big : small;
        const spaceHorizontal = this.gameSize.width * spaceMultiplier;
        const cardWidth =
            (this.gameSize.width - spaceHorizontal * (this.maxColumns - 1)) / this.maxColumns;
        const cardHeight = cardWidth / (140 / 190);
        this.cardSize = {
            width: cardWidth,
            height: cardHeight,
        };

        this.tableauCardSpace = cardHeight * 0.34;
        this.spaceHorizontal = spaceHorizontal;

        this.calcDisplay();
    }

    protected getTableauVerticalSpace(baseTableauY: number, tabIndex: number) {
        const tabCount = cardsQuery.getTableauCounts()[tabIndex];
        if (!tabCount) {
            return this.tableauCardSpace;
        }

        const maxY = baseTableauY + (tabCount - 1) * this.tableauCardSpace + this.cardSize.height;
        if (maxY > this.gameSize.height - 60) {
            const h = this.gameSize.height - 60 - baseTableauY - this.cardSize.height;
            return h / (tabCount - 1);
        }

        return this.tableauCardSpace;
    }

    destroy() {
        this.subscription.unsubscribe();
    }
}
