<template>
    <div class="max-w-screen-sm m-auto w-full">
        <div class="flex w-full mb-3 justify-center">
            <div class="mr-4">{{ timeFormat }}</div>
            <div class="ml-4">{{ metrics.moves }} moves</div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { gameQuery } from '@/state/game/game.query';
import { GameMetrics } from '@/core/models';
import { useObservable } from '@/core/rxjs-helpers';
import { coreUtil } from '@/core/core-util';

export default defineComponent({
    setup() {
        const metrics = useObservable<GameMetrics>(
            gameQuery.select((s) => s.gameMetrics),
            {
                time: 0,
                moves: 0,
                score: 0,
                undoCount: 0,
                hintCount: 0,
            }
        );

        const timeFormat = computed(() => coreUtil.timeFormat(metrics.value.time));

        return {
            metrics,
            timeFormat,
        };
    },
});
</script>
