
import { computed, defineComponent, ref } from 'vue';
import { gameQuery } from '@/state/game/game.query';
import { Card, CardOwner, GameStatus, Position } from '@/core/models';
import { CardDragEvent, coreBus, DragEventType } from '@/core/core-bus';
import { InteractiveOptions, useInteractive } from '@/composable/interactive';
import { subscribeTo } from '@/core/rxjs-helpers';
import { cardImages } from '@/core/card-images';
import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { cardDisplayFactory } from '@/core/display';
import { recomputable } from '@/composable/recomputable';

export default defineComponent({
    props: {
        cardId: { type: String, required: true },
        hideStockCard: { type: Boolean },
    },

    setup(props) {
        const el = ref<HTMLElement | null>(null);
        // eslint-disable-next-line
        const card = ref(cardsQuery.getEntity(props.cardId)!);
        const isMovingAsMeld = ref(false);
        // eslint-disable-next-line
        let gameStatus = GameStatus.none;
        // eslint-disable-next-line
        let currentPosition: Position = { x: 0, y: 0 };
        const recompute = ref(0);

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const options = {
            el,
            onDragMove: (x, y) => {
                coreBus.cardDragEvent$.next({
                    cardId: card.value.id,
                    card: card.value,
                    type: DragEventType.move,
                    x,
                    y,
                });
            },
            onDragEnd: (x, y) => {
                coreBus.cardDragEvent$.next({
                    cardId: card.value.id,
                    card: card.value,
                    type: DragEventType.end,
                    x,
                    y,
                });
            },
            onDragStart: (x, y) => {
                coreBus.cardDragEvent$.next({
                    cardId: card.value.id,
                    card: card.value,
                    type: DragEventType.start,
                    x,
                    y,
                });
            },
            onClick: () => {
                coreBus.cardClickEvent$.next({
                    cardId: card.value.id,
                    card: card.value,
                });
            },
            onMoveAnimeStart: () => {
                cardsService.update(card.value.id, {
                    isMoving: true,
                });
            },
            onMoveAnimeComplete: () => {
                cardsService.update(card.value.id, {
                    isMoving: false,
                });
            },
        } as InteractiveOptions;

        const interactive = useInteractive(options);

        const updatePointerEvents = (card: Card) => {
            if (card.dragEnabled) {
                interactive.enable();
            } else if (card.clickEnabled) {
                interactive.enable(true);
            }
            if (!card.dragEnabled && !card.clickEnabled) {
                interactive.disable();
            }
        };

        const handleGlobalDragEvents = (ev: CardDragEvent) => {
            // if dragged card is parent of this card we need to move this card as well
            // so they move as one unit
            if (card.value.owner != CardOwner.tableau) {
                return;
            }
            if (isMovingAsMeld.value && ev.type == DragEventType.end) {
                isMovingAsMeld.value = false;
            }
            if (
                !(
                    card.value.owner == ev.card.owner &&
                    card.value.ownerIndex == ev.card.ownerIndex &&
                    card.value.order > ev.card.order &&
                    card.value.id != ev.cardId
                )
            ) {
                return;
            }

            if (ev.type == DragEventType.move) {
                const nx = ev.x;
                const ny =
                    ev.y +
                    cardDisplayFactory.get.tableauCardSpace * (card.value.order - ev.card.order);
                interactive.moveTo(nx, ny, 0);
            } else if (ev.type == DragEventType.start) {
                isMovingAsMeld.value = true;
            } else {
                isMovingAsMeld.value = false;
            }
        };

        const moveCardToPosition = (duration: number) => {
            // make sure to restore opacity is 1;
            const elm = el.value;
            if (elm && elm.style.opacity == '0') {
                elm.style.opacity = '1';
            }

            const pos = cardDisplayFactory.get.calcCardPosition(card.value);
            interactive.moveTo(pos.x, pos.y, duration);
            // eslint-disable-next-line
            currentPosition = pos;
        };

        subscribeTo(cardsQuery.selectEntity(card.value.id), (c) => {
            if (c) {
                card.value = c;
                updatePointerEvents(c);
            }
        });

        subscribeTo(coreBus.cardMoveCmd$, (c) => {
            if (c.cardId == card.value.id) {
                moveCardToPosition(c.duration);
            }
        });

        subscribeTo(coreBus.cardDragEvent$, (ev) => {
            handleGlobalDragEvents(ev);
        });

        subscribeTo(gameQuery.gameStatus$, (status) => {
            // eslint-disable-next-line
            gameStatus = status;
            updatePointerEvents(card.value);
        });

        subscribeTo(coreBus.shakeCardCmd$, (ev) => {
            if (ev.card.id == card.value.id) {
                const xMax = cardDisplayFactory.get.cardSize.width * 0.08;
                interactive.shake(xMax);
            }
        });

        subscribeTo(coreBus.cardSlidOutDownCmd$, (c) => {
            if (c.card.id == card.value.id) {
                interactive.slideDownAndFade(cardDisplayFactory.get.gameSize.height);
            }
        });

        const image = computed(() => {
            if (card.value.isFaceUp) {
                return cardImages[card.value.name ? card.value.name : card.value.id];
            }
            return cardImages.back;
        });

        const cardClass = computed(() => {
            const arr = [];
            if (!card.value.isFaceUp) {
                arr.push('back');
            }
            if (card.value.isShadow) {
                arr.push('shadow');
            }
            if (card.value.isHighlight) {
                arr.push('highlight');
            }
            if (card.value.owner == CardOwner.none) {
                arr.push('hide');
            }
            if (
                props.hideStockCard &&
                card.value.owner == CardOwner.stock &&
                !card.value.isMoving
            ) {
                arr.push('hide');
            }
            if (card.value.isHint) {
                arr.push('hint1');
            }
            return arr;
        });

        const cardStyle = recomputable(recompute, () => {
            /* eslint-disable prettier/prettier */
            const zIndex =
                card.value.isDragging || card.value.isMoving || isMovingAsMeld.value
                    ? card.value.order + 100
                    : card.value.owner == CardOwner.stock && props.hideStockCard
                        ? 0
                        : card.value.order;
            const bg =
                !card.value.dragEnabled && card.value.owner == CardOwner.tableau
                    ? '#bfbfbf'
                    : '#eeeeee';
            return {
                zIndex,
                width: `${cardDisplayFactory.get.cardSize.width}px`,
                height: `${cardDisplayFactory.get.cardSize.height}px`,
                borderRadius: `${cardDisplayFactory.get.cardSize.width * 0.06}px`,
                backgroundColor: bg,
            };
        });

        const imageStyle = recomputable(recompute, () => {
            return {
                width: `${cardDisplayFactory.get.cardSize.width}px`,
                height: `${cardDisplayFactory.get.cardSize.height}px`,
            };
        });

        return {
            image,
            cardClass,
            cardStyle,
            imageStyle,
            el,
        };
    },
});
