
import { defineComponent, computed } from 'vue';
import { gameQuery } from '@/state/game/game.query';
import { GameMetrics } from '@/core/models';
import { useObservable } from '@/core/rxjs-helpers';
import { coreUtil } from '@/core/core-util';

export default defineComponent({
    setup() {
        const metrics = useObservable<GameMetrics>(
            gameQuery.select((s) => s.gameMetrics),
            {
                time: 0,
                moves: 0,
                score: 0,
                undoCount: 0,
                hintCount: 0,
            }
        );

        const timeFormat = computed(() => coreUtil.timeFormat(metrics.value.time));

        return {
            metrics,
            timeFormat,
        };
    },
});
