
import { ref, defineComponent, computed, onMounted, onBeforeUnmount, PropType } from 'vue';
import { GameStatus } from '@/core/models';
import { subscribeTo, useObservable } from '@/core/rxjs-helpers';
import { cardsQuery } from '@/state/cards/cards.query';
import { useRoute } from 'vue-router';
import interact from 'interactjs';
import { gameService } from '@/state/game/game.service';
import { gameQuery } from '@/state/game/game.query';
import { appService } from '@/state/app.service';
import { tracking } from '@/core/tracking';
import { appQuery } from '@/state/app.query';
import confetti from 'canvas-confetti';
import { coreBus } from '@/core/core-bus';
import { useWindowResize } from '@/composable/resize';
import { isNaN } from 'lodash';
import CardView from '@/components/CardView.vue';
import ActionPanel from '@/components/ActionPanel.vue';
import StatusPanel from '@/components/StatusPanel.vue';
import GameCompletePanel from '@/components/GameCompletePanel.vue';
import GameMenu from '@/components/GameMenu.vue';
import { ControllersBase } from '@/core/controllers.base';
import { CardDisplayBase } from '@/core/card-display.base';
import { cardDisplayFactory } from '@/core/display';

export default defineComponent({
    components: {
        CardView,
        ActionPanel,
        StatusPanel,
        GameCompletePanel,
        GameMenu,
    },

    props: {
        showHintButton: { type: Boolean, default: false },
        hideDailyChallenge: { type: Boolean },
        controllers: { type: Object as PropType<ControllersBase>, required: true },
        hideStockCard: { type: Boolean },
        display: { type: Object as PropType<CardDisplayBase> },
    },

    setup(props) {
        const gameStage = ref<HTMLElement | null>(null);
        const showGameCompleteView = ref(false);
        const gameId = ref(0);
        const gameStatus = ref(GameStatus.none);
        const showGameMenu = ref(false);
        const cards = useObservable(cardsQuery.cards$, []);
        const route = useRoute();

        // intercatjs: i put this here instead of main.js cause i don't want this lib to include in vendors chunk
        interact.pointerMoveTolerance(5);

        // we need to make sure game status is none, before init controllers
        gameService.setGameStatus(GameStatus.none);

        // set display
        if (props.display) {
            props.display.init();
            cardDisplayFactory.setInstance(props.display);
        }

        subscribeTo(gameQuery.gameId$, (id) => {
            gameId.value = id || 0;
        });

        subscribeTo(gameQuery.gameStatus$, (status) => {
            gameStatus.value = status;
            if (status == GameStatus.gameCompleted) {
                appService.setDisablePageScroll(false);
                tracking.event('game-completed', {
                    eventCategory: appQuery.getActiveGame().toString(),
                });
                setTimeout(() => {
                    showGameCompleteView.value = true;
                    confetti({
                        particleCount: 120,
                        spread: 60,
                    });
                }, 500);
            } else {
                showGameCompleteView.value = false;
                appService.setDisablePageScroll(true);
            }

            if (status == GameStatus.dealCompleted) {
                tracking.event('game-deal-completed', {
                    eventCategory: appQuery.getActiveGame().toString(),
                });
            }
        });

        subscribeTo(coreBus.openGameMenu$, () => {
            showGameMenu.value = true;
        });

        const updateDims = () => {
            const container = gameStage.value as HTMLElement;
            if (container && container.clientWidth > 0) {
                gameService.setGameSize(container.clientWidth, container.clientHeight);
            }
        };

        useWindowResize(updateDims);

        const game = computed(() => appQuery.getActiveGame());

        onMounted(() => {
            props.controllers.init();
            updateDims();
            const sid = route.params.gameId as string | undefined;
            const gameId = sid && !isNaN(sid) ? parseInt(sid, 10) : null;
            coreBus.startGameCmd$.next({
                gameId,
                game: game.value,
            });
        });

        const isGameInProgress = computed(
            () => gameStatus.value == GameStatus.running || gameStatus.value == GameStatus.paused
        );

        const startNewGame = (gameId: number | null) => {
            coreBus.startGameCmd$.next({
                gameId,
                game: game.value,
            });
        };

        const statsClicked = () => {
            coreBus.showGameStatisticDialogCmd$.next({
                game: game.value,
            });
        };

        onBeforeUnmount(() => {
            if (props.display) {
                props.display.destroy();
                cardDisplayFactory.clearInstance();
            }
            props.controllers.destroy();
            gameService.setGameStatus(GameStatus.none);
            appService.setDisablePageScroll(false);
        });

        return {
            showGameCompleteView,
            isGameInProgress,
            startNewGame,
            showGameMenu,
            cards,
            gameStage,
            statsClicked,
            game,
            gameId,
        };
    },
});
